<template>
  <div class="games">
    <v-card class="mt-3" v-if="loading">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-card>

    <div v-else>
      <v-card>
        <div class="top-card-background">
          <v-img class="institution-logo" width="80" :src="srcLogoImage">
          </v-img>
          <span class="top-card-text">Dialéticos</span>
        </div>
      </v-card>

      <v-card class="mt-2">
        <v-card-title> Sobre </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.explanation"
                :loading="loading"
                class="required"
                hint="Breve texto geral sobre a instituição dialéticos."
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-1">
            <v-col>
              <v-btn small color="primary" @click="saveText()">
                <v-icon color="white" left>mdi-check</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title> Debates </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="games"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                disable-pagination
                hide-default-footer
                show-expand
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      rounded
                      color="success"
                      @click="redirectToNewGameScreen()"
                    >
                      + Novo debate
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="pt-2 pb-2">
                      <v-col sm="12">
                        <v-btn
                          @click="redirectToGameScreen(item.id)"
                          small
                          text
                        >
                          <v-icon left>mdi-arrow-right-bold-box</v-icon>
                          Entrar</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-2 pb-2">
                      <v-col sm="12">
                        <v-btn
                          v-if="isAdmin"
                          small
                          text
                          @click="redirectToUpdateGameScreen(item.id)"
                        >
                          <v-icon left>mdi-pencil</v-icon> Editar</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pt-2 pb-2">
                      <v-col sm="12">
                        <v-btn
                          v-if="isAdmin"
                          small
                          text
                          @click="deleteGames(item.id)"
                        >
                          <v-icon left>mdi-delete</v-icon>
                          Excluir</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-dialog v-model="successDialog" persistent max-width="450">
            <v-card>
              <v-card-text>
                <div class="text-center pa-4">
                  <v-icon large color="success">mdi-check</v-icon>
                </div>

                <div class="text-center pa-3">
                  <h3>Operação realizada com sucesso</h3>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click.stop="successDialog = false"
                  >Ok</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import dialeticsLogo from "@/assets/dialetic-phi-logo.png";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      successDialog: false,
      gameIdForUpdateModerators: null,
      institutionInfo: null,
      stateEntityInfo: null,
      singleExpand: true,
      srcLogoImage: dialeticsLogo,
      form: {},
      headers: [
        { text: "Tema", value: "theme" },
        { text: "Pertence ao", value: "StateEntity.name" },
        { text: "Ações", value: "data-table-expand" },
      ],
      expanded: [],
      moderators: [],
      games: [],
    };
  },
  methods: {
    async getGames() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/admin/jogos/dialeticos`);

        this.setGamesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setGamesData(data) {
      const cpData = { ...data };

      this.games = cpData.games;
    },
    async getDialeticsInstitutionData() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/instituicoes/dialeticos`);

        const cpData = { ...res.data };

        this.form.explanation = cpData.explanation;

        this.getGames();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteGames(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Alerta de AÇÃO IRREVERSÍVEL",
          `Deseja realmente remover este debate do sistema? `
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/jogos/${id}`;

        await this.$axios.delete(url);

        this.deleteGamesFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteGamesFromTable(id) {
      const index = this.games.findIndex((p) => p.id === id);

      this.games.splice(index, 1);
    },
    async saveText() {
      try {
        const confirm = await this.$root.$confirm(
          "Confirmação",
          `Salvar texto? `
        );

        if (!confirm) return;

        this.loading = true;

        this.$axios.put("/instituicoes/dialeticos/1", {
          explanation: this.form.explanation,
        });

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    redirectToGameScreen(gameId) {
      this.$router.push(`/jogo/${gameId}/dialeticos`);
    },
    redirectToNewGameScreen() {
      this.$router.push(`/jogos/dialeticos/cadastrar`);
    },
    redirectToUpdateGameScreen(id) {
      this.$router.push(`/jogos/${id}/dialeticos/editar`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    roomId() {
      return this.$route.params.roomId;
    },
    entityId() {
      return this.$route.params.entityId;
    },

    isAdmin() {
      return this.userData.Group.group_code === 1 ? false : true;
    },
  },
  watch: {
    roomId(value) {
      if (!value) return;

      this.getRommInfo();
    },
  },
  created() {
    this.getDialeticsInstitutionData();
  },
};
</script>

<style scoped>
.top-card-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #bbbbbb, #3a3a3a);
  min-height: 150px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.top-card-text {
  color: #111010;
}
</style>
